require('core-js');require('regenerator-runtime/runtime');import $ from 'jquery';
import { TweenMax } from "gsap";
window.jQuery = $;
window.$ = $;
require('../../../wdh-feature/wdh-feature-b2b/src/js/b2b-shop');

// PROJECT
require('./project/header');
require('./project/custom-a-login-spot');

// FOUNDATION

// FEATURE
require('@demant/wdh-gdpr/lib/default.implementation.js');
require('../demant-feature/dashboard/js/index');